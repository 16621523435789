import {
	mapState,
	mapActions
} from 'vuex'
import Vue from 'vue'
import {
	Empty
} from 'vant'
import {
	Popup,
	Toast,
	Dialog
} from 'vant'
import con from '../../utils/common'
import server from '../../utils/config'

Vue.use(Empty)
Vue.use(Toast)
Vue.use(Dialog)
export default {
	name: 'warranty',
	components: {},
	data() {
		return {
			active: 0,
			// 保单列表
			warrantyList: [],
			waitPayList: [],
			UnderwritingList: [],
			takeEffectList: [],
			oldTaskList: [],
			noback: true,
			phone: this.getUrlParam('phone'),
			ctitle: '待承保',
			//产品代码
			productCode: '',

		}
	},
	beforeCreate() {

	},
	created() {
		console.log('接口地址：', server);

		const noback1 = this.getUrlParam('noback');
		const code = this.getUrlParam('code') || '';
		this.productCode = code;
		localStorage.removeItem('productCode')
		if (this.productCode === 'CZHKB-01' || this.productCode === 'CZHKB2024-01') {
			localStorage.setItem('productCode', this.productCode)
		}
		if (code == 'ZHB01') {
			this.ctitle = '已缴费'
		} else {
			this.ctitle = '待承保'
		}

		this.cCode = this.getUrlParam('code');

		if (code != '' && code != undefined && code != null) {
			localStorage.setItem('code', code)
		}

		if (noback1 != '' && noback1 != undefined && noback1 != null) {
			this.noback = false;
		} else {
			this.noback = true
		}
		const token = this.getUrlParam('token')
		if (token != '' && token != undefined && token != null) {
			localStorage.setItem('token', token)
		}
		Toast.loading({
			message: '加载中...',
			duration: 0,
			forbidClick: true,
		})
		this.myWarranty({
			bxOrderState: 99,
			formerYears: 2
		}).then(res => {
			if (res.code == 200) {
				let arr = []
				res.list.forEach((item) => {
					if ((item.productCode.includes('JWYCX') || item.productCode.includes('02IC')) &&
						item.status === 0) {

					} else {
						arr.push(item)
					}
				})
				this.warrantyList = arr
				Toast.clear()
			}
		})
	},
	methods: {
		...mapActions('warranty', ['myWarranty', 'queryorder', 'taOutLogin', 'czhkbOrderDetail', 'czhkbOrderCancel']),
		//查看详情
		todetail(ids, types, orderType, url) {
			//http://h5test-saas.aalib.com.cn/product/tahmbPayIcbc.do?orderId=2a2434da-513e-4be7-8ea9-4567df3a9d1f
			url = url || '';
			let orderId = url.substr(url.indexOf('orderId=') + 8);
			console.log(`全联订单号：`, url, orderId)
			if (orderType != undefined && orderType != null && orderType != 'null' && orderType != '') {
				this.$router.push({
					name: 'Wardetail',
					query: {
						id: ids,
						type: types,
						orderTypes: orderType,
						orderId: orderId
					}
				})
			} else {
				this.$router.push({
					name: 'Wardetail',
					query: {
						id: ids,
						type: types,
						orderTypes: 'a',
						orderId: orderId
					}
				})
				//orderType为undefined，传0和1之外的值
			}

		},
		back() {
			this.$router.go(-1)
		},
		//退出登录
		outLogin() {
			let that = this;
			Dialog.confirm({
					title: '系统提示',
					message: '确认退出登录!',
					confirmButtonText: '确定',
					confirmButtonColor: "#5d6d8e",
					showCancelButton: true,
					className: 'tishi'
				}).then(() => {
					if (localStorage.getItem('code')) {
						this.taOutLogin({
							code: localStorage.getItem('code')
						}).then(res => {
							if (res.code == 200) {
								if (res.sign == "" || res.sign == null) {
									window.location.href = con.bxLoginUrl
								} else if (res.sign == "XMLHB-01") {
									window.location.href = con.xiaMenLoginUrl
								} else if (res.sign == "AYHMB-01") {
									window.location.href = con.anYangLoginUrl
								} else if (res.sign == "ZHB01") {
									window.location.href = con.zaoZhuangLoginUrl
								} else if (res.sign == "TAHMB-02") {
									window.location.href = con.taiAn2022LoginUrl
								} else if (res.sign == "QLB") {
									window.location.href = con.quanLianBaoLoginUrl
								} else if (res.sign == "CZHKB-01" || res.sign == 'CZHKB2024-01') {
									that.czQuitJump();
								}

							}
						})
					} else {
						this.taOutLogin({}).then(res => {
							if (res.sign == "" || res.sign == null) {
								window.location.href = con.bxLoginUrl
							} else if (res.sign == "XMLHB-01") {
								window.location.href = con.xiaMenLoginUrl
							} else if (res.sign == "AYHMB-01") {
								window.location.href = con.anYangLoginUrl
							} else if (res.sign == "ZHB01") {
								window.location.href = con.zaoZhuangLoginUrl
							} else if (res.sign == "TAHMB-02") {
								window.location.href = con.taiAn2022LoginUrl
							} else if (res.sign == "QLB") {
								window.location.href = con.quanLianBaoLoginUrl
							} else if (res.sign == "CZHKB-01" || res.sign == "CZHKB2024-01") {
								that.czQuitJump();
							}
						})
					}
				})
				.catch(() => {
					// on cancel
				})
		},
		/**
		 * 潮州：退出跳转
		 */
		czQuitJump() {
			if (server.indexOf('iwy.jk-cloud.com.cn') > -1) {
				window.location.href = con.chaoZhouHuiKangBaoUrl
				return;
			}
			window.location.href = con.chaoZhouHuiKangBaoTestUrl
		},
		playApplication(id) {
			//申请理赔
			this.changeData()
			this.$router.push({
				name: 'settlement',
				query: {
					bxId: id
				}
			})
		},
		waitPay(e) {
			if (e == 1) {
				if (this.waitPayList.length == 0) {
					Toast.loading({
						message: '加载中...',
						duration: 0,
						forbidClick: true,
					})
				}
				this.myWarranty({
					bxOrderState: 0,
					formerYears: 2
				}).then(res => {
					if (res.code == 200) {
						// let arr = []
						// res.list.forEach((item)=>{
						//   if((item.productCode.includes('JWYCX')||item.productCode.includes('02IC'))  && item.status === 0){
						//
						//   }else{
						//     arr.push(item)
						//   }
						// })
						this.waitPayList = res.list
					}
					Toast.clear()
				})

			} else if (e == 2) {
				if (this.UnderwritingList.length == 0) {
					Toast.loading({
						message: '加载中...',
						duration: 0,
						forbidClick: true,
					})
				}
				this.myWarranty({
					bxOrderState: 1,
					formerYears: 2
				}).then(res => {
					if (res.code == 200) {
						this.UnderwritingList = res.list
					}
					Toast.clear()

				})

			} else if (e == 3) {
				var list = []
				if (this.takeEffectList.length == 0) {
					Toast.loading({
						message: '加载中...',
						duration: 0,
						forbidClick: true,
					})
				}
				this.myWarranty({
					bxOrderState: 2,
					formerYears: 2
				}).then(res => {
					if (res.code == 200) {
						this.takeEffectList = list.concat(res.list)
					}
					Toast.clear()
				})

			} else if (e == 4) {
				var list = []
				if (this.oldTaskList.length == 0) {
					Toast.loading({
						message: '加载中...',
						duration: 0,
						forbidClick: true,
					})
				}
				this.myWarranty({
					bxOrderState: 99,
					formerYears: 1
				}).then(res => {
					if (res.code == 200) {
						this.oldTaskList = list.concat(res.list)
					}
					Toast.clear()
				})

			} else if (e == 0) {
				if (this.warrantyList.length == 0) {
					Toast.loading({
						message: '加载中...',
						duration: 0,
						forbidClick: true,
					})
				}
				this.myWarranty({
					bxOrderState: 99,
					formerYears: 2
				}).then(res => {
					if (res.code == 200) {
						this.warrantyList = res.list

					}
					Toast.clear()
				})
			}

		},
		//去支付
		gopay(url) {
			if (!url) {
				Toast({
					message: "支付链接未生成",
					className: "toas"
				});
				return;
			}
			window.location.href = url
		},
		loadList(bxOrderState, formerYears) {
			if (bxOrderState === 0) {

			} else {
				bxOrderState = bxOrderState || '99'
			}
			formerYears = formerYears || '2'
			this.myWarranty({
				bxOrderState: bxOrderState,
				formerYears: formerYears,
				v: new Date()
			}).then(res => {
				if (res.code == 200) {
					this.warrantyList = res.list
				}
			})
		},
		/**
		 * 信息弹窗
		 * @param {Object} msg
		 */
		alert(msg) {
			Dialog.alert({
				confirmButtonText: '确定',
				confirmButtonColor: '#5d6d8e',
				showCancelButton: false,
				className: 'tishi',
				title: '系统提示',
				message: msg || '系统异常',
			})
		},
		//潮州取消订单
		czhkbCancelOrderSearch(item) {
			Dialog.confirm({
					message: '确定取消订单吗？',
					className: 'sure'
				})
				.then(() => {
					Toast.loading({
						message: '加载中...',
						duration: 0,
						forbidClick: true,
					})
					this.czhkbOrderDetail({
						orderNo: item.externalNo
					}).then(resp => {
						if (resp.code != 200) {
							Toast.clear();
							this.alert(resp.msg);
							return;
						}
						if (resp.data.orderState === 0) {
							this.czhkbCancelOrder(item.appId || 'aebf1162', item.orderNo, item
								.externalNo, item.bxId);
							return;
						}
						Toast.clear();
						this.alert('非待支付订单不允许取消订单');
					}).catch(error => {
						console.log('潮州取消查询订单', error)
						Toast.clear();
						this.alert("网络不稳定，请稍后再试");
					});
				})
				.catch(() => {
					// on cancel
				})
		},
		//潮州取消订单
		czhkbCancelOrder(appId, orderNo, externalNo, bxId) {
			let cancelParam = {
				"appid": appId,
				"orderNo": orderNo,
				"externalNo": externalNo,
			};
			this.czhkbOrderCancel(cancelParam).then(resp => {
				if (resp.code != 200) {
					Toast.clear();
					this.alert(resp.msg);
					return;
				}
				this.loadList(0, 2);
				for (let i = this.warrantyList.length - 1; i > 0; i--) {
					if (this.warrantyList[i].bxId === bxId) {
						delete this.warrantyList[i];
					}
				}
				Toast.clear();
				this.alert('订单取消成功');
			}).catch(error => {
				console.log('潮州取消订单', error)
				Toast.clear();
				this.alert("网络不稳定，请稍后再试");
			});
		},
		//查询订单并取消订单
		queryPay(id) {
			console.log(`查询订单并取消订单:`, id);
			if (!id) {
				Toast({
					message: "取消订单编号为空",
					className: "toas"
				});
				return;
			}
			Dialog.confirm({
					message: '确定取消订单吗？',
					className: 'sure'
				})
				.then(() => {
					this.queryorder({
						id: id
					}).then(res => {
						console.log(`订单查询：`, res)
						if (res.code == 200) {
							this.loadList(0, 2);
							return;
						}
						Dialog.alert({
							confirmButtonText: '确定',
							confirmButtonColor: '#5d6d8e',
							showCancelButton: false,
							className: 'tishi',
							title: '系统提示',
							message: res.msg || '系统异常',
						})
					})
				})
				.catch(() => {
					// on cancel
				})
		},
		queryPay1(id) {
			var that = this
			console.log(`查询订单并取消订单:`, id);
			if (!id) {
				Toast({
					message: "取消订单编号为空",
					className: "toas"
				});
				return;
			}
			Dialog.confirm({
					message: '确定取消订单吗？',
					className: 'sure'
				})
				.then(() => {
					that.queryorder({
						id: id
					}).then(res => {
						console.log(`订单查询：`, res)
						if (res.code == 200) {
							that.loadList(99, 2);
						} else {
							Toast(res.msg)
						}
					})
				})
				.catch(() => {
					// on cancel
				})
		},
		getUrlParam(name) {

			var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
			var r = window.location.search.substr(1).match(reg)
			if (r != null) return unescape(r[2])
			return null
		}
	},
}